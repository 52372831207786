.dashboard-menu {
    width: 234.82px;
    height: 100%;
    background: blue;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    transition: 0.5s;

}

.dashboard-menu-icon {
    margin-left: 24px;
}

.dashboard-menu-close {
    text-align: right;
    cursor: pointer;
}

.dashboard-menu-logout {
    cursor: pointer;
}

.sidebar-item {
    display: flex;
    align-items: center;
}

.badge {
    border-radius: 50%;
    background-color: #F94F5D;
    font-weight: normal;
    font-size: 10px;
    letter-spacing: 0.02em;
    line-height: 23px;
    text-align: left;
    color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
}


