.underage-container {
    width: 270px;
    height: 270px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.underage-items {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.underage-items p {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #9aa0aa;
}

.underage-items img {
    width: 50px;
    margin-bottom: 20px;
}