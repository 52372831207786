.navigation-button-next {
    width: 50px;
}

.navigation-button-back {
    width: 20px;
}
.empty-button {
    cursor: pointer;
    background-color: transparent;
    border: 0px;
}

.navigation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: -34px;
    padding: 30px 0px 40px 0px;
}

.navigation-container-title {
    font-family: Lato;
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: left;
    color: #fff;
}