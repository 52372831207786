.navigation-header-fixed-container {
    position: fixed;
    top: 0px;
    z-index: 1000;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
}

.nav-content {
    list-style-type: none;
    font-weight: normal;
    font-size: 1em;
    line-height: 19px;
    color: #343434;
    padding: 0px;
}

.navigation-header-container {
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 600px) {
    .navigation-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        height: 70px;
        align-items: center;
    }

    .nav-content {
        display: flex;
        margin-right: 30px;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .nav-links {
        margin-right: 25px;
    }
}

.login-button-inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.login-button-inner-wrapper img {
    margin-right: 10px;
}

.login-button-wrapper button {
    width: 123.67px;
    height: 44.75px;
    border-radius: 22.37px;
    background: #f94f5d;
    color: white;
    border: none;
    cursor: pointer;
}

.nav-content-min {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
}

.navigation-header-icons-min img {
    margin-right: 20px;
}

.nav-social-links {
    display: flex;
}
.nav-social-links img {
    margin-right: 10px;
}
.nav-links-login {
    margin-right: 15px;
}