
.treatment-section {
  background-repeat: no-repeat; 
  background-size: 100% 495px;
  background-image: linear-gradient(to left, #ebb178 0%, #f9595c 44.04%, #f94f5d 100%);
  margin-bottom: 87px;
}

.treatment-header {
  text-align: center;
}

.treatment-back-white {
  height: 321px;
}

.treatment-gradient {
  height: 495px;
}
  
.treatment-header-title {
    font-weight: 300;
    font-size: 2em;
    line-height: 22px;
    text-align: center;
    color: #fff;
    padding-top: 40px;
}

.treatment-header-subtitle {
    font-weight: normal;
    font-size: 0.9em;
    line-height: 22px;
    text-align: center;
    color:rgb(255, 255, 255);
    background: transparent;
    padding-top: 10px;
    margin: 0px 15px 0 15px;
}

.treatment-header-p {
    font-family: Lato;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.8em;
    text-align: left;
    color: #fff;
    padding: 45px 0px 45px 0px;
}


@media screen and (max-width: 600px) {
  .treatment-section {
    background-size: 100% 600px;
  }
  .treatment-header-p {
    padding: 45px 30px 45px 30px;
  }
}
  
.treatment-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.treatment-item {
  font-family: Lato;
  font-weight: normal;
  font-size: 1em;
  line-height: 22px;
  text-align: left;
  color: #727272;
  padding: 0px;
  width: 300px;
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 1024px) {
  .treatment-item {
      margin: 0px auto 10px auto;
  }
}

.treatment-item img {
  height: 50px;
}

.treatment-item-title {
  font-family: Lato;
  font-weight: 300;
  font-size: 25px;
  line-height: 22px;
  color: #f94f5d; 
  padding: 20px; 
}

.treatment-item p {
  color: #727272;
  text-align: left;
}

.treatment-item ul {
  text-align: left;
}

.treatment-custom-list li {
line-height: 3em;
}
  