.forgot-password-container {
    text-align: center;
    margin-top: 160px;
    color: #fff;
}

.forgot-password-container-title {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    margin-bottom: 20px;
}

.forgot-password-container-subtitle {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 40px;
}

.forgot-password-form {
    margin-bottom: 40px;
}

.forgot-password-message {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    max-width: 250px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #f94f5d;
}