.sidebar-shadow {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #343434;
    opacity: 0.65;
    z-index: 1;
}

.sidebar-menu {
    width: 234.82px;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2;
    transition: 0.5s;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.sidebar-close {
    height: 77px;
}

.sidebar-close img {
    top: 20px;
    right: 14px;
    position: absolute;
    cursor: pointer;
}

.sidebar-content > .sidebar-item {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.02em;
    line-height: 19px;
    text-align: left;
    color: #9aa0aa;
    padding: 20px;
}

.sidebar-content > .sidebar-item-strong {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.02em;
    line-height: 19px;
    text-align: left;
    color: #fff;
    background: #343434;
    opacity: 0.15;
    padding: 20px;
    height: 50px;
    display: flex;
    align-items: center;
}

.about-container-sidebar {
    margin-left: 20px;
    margin-top: 20px;
}