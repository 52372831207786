
.about-container {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    color: #f94f5d;
}

.about-container-grey {
    color: #9aa0aa;
}

.about-container img {
    width: 18px;
    margin-right: 5px;
}

.spinner-loading img {
    width: 70px;
}