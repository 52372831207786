.profile-picture-header {
    text-align: center;
    margin-bottom: 28px;
}

.profile-picture-header-name {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #fff;
}

.profile-picture-header-message {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 21px;
    text-align: center;
    color: #fff;
    opacity: 0.5;    
}