.treatment-markup-container {
    padding: 15px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    overflow: auto;
    max-height: 400px;
    font-family: Lato;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #9aa0aa;
}

.treatment-selection-main {
    position: relative;
    width: 300px;
    margin: auto;
}

.treatment-selection-gradient-div-red {
    background: linear-gradient(45deg, #f94f5d  0%, #f9595c 44.04%,  #ebb178 100%);
    color: white;
}

.treatment-selection-gradient-div-green {
    background: linear-gradient(45deg, #2196aa 0%, #33ccae 100%);
    color: white;
}

.treatment-selection-gradient-div-blue {
    background: linear-gradient(45deg, #207cdf 0%, #2884e4 39.41%, #8ed8d0 100%);
    color: white;
}

.treatment-selection-container .treatment-selection-gradient-div {
    height: 200px;
}


.treatment-selection-content {
    position: relative;
}


.treatment-selection-link {
    position: relative;
    cursor: pointer;
    background: none;
}

.treatment-step-next {
    position: absolute;
    right:0px;
    bottom: -27px;
}


.choices-container button {
    background: none;
    border: 0px;
    padding: 0px;
    cursor: pointer;
}

.treatment-choice {
    margin-bottom: 20px;
    background: transparent;
    width: 305.07px;
    height: 129.17px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    font-family: Lato;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
    color: #9aa0aa;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.button-container {
    margin-right: 20px;
}

.treatment-choice span {
    margin-left: 20px;
}

.treatment-choice img {
    margin-right: 20px;
}

.treatment-markup-container {
    padding-top: 40px;
}
.treatment-markup-container ol {
    list-style: none;
    counter-reset: my-awesome-counter;
}

.treatment-markup-container ol li {
    counter-increment: my-awesome-counter;
    margin: 0 0 2rem 0;
    position: relative;
}

.treatment-markup-container ol li::before {
    content: counter(my-awesome-counter);
    font-family: Lato;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    text-align: left;
    color: #9aa0aa;
    position: absolute;
    --size: 21px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    
    border-radius: 50%;
    text-align: center;
    border: 1px solid #9aa0aa;
}


