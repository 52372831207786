.dashboard {
    color: white;
}

.dashboard-profile-container {
    height: 186px;
    width: 360px;
    background: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: auto;
}

.dashboard-opacity {
    height: 186px;
    background: rgba(52, 52, 52, 0.51);
    position: relative;
}

.dashboard-header {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: left;
    color: #fff;
    position: absolute;
    bottom: 15px;
    left: 20px;
}

.dashboard-items {
    color: gray;
    padding: 20px 28px 20px 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dashboard-item {
    width: 133.24px;
    height: 107px;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.dashboard-item-title {
    font-weight: 300;
    font-size: 23px;
    letter-spacing: 0.02em;
    line-height: 24px;
}

.dashboard-item-subtitle {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.01em;
    line-height: 21px;
    opacity: 0.75;
    color: #9aa0aa;   
}

.dashboard-item-disabled { 
    color: #9aa0aa;
    border-radius: 4px;
    border: 1px solid #9aa0aa; 
}

.dashboard-item-subtitle-disabled { 
    opacity: 0.5;
}

.dashboard-item-orange {
    border: 1px solid #f9764f;
    color: #f9764f;
}

.dashboard-item-green {
    border: 1px solid #2196aa;
    color: #2196aa;
}

.dashboard-item-blue {
    border: 1px solid #207cdf;
    color: #207cdf;
}

.dashboard-item-violet {
    border: 1px solid #8122bd;
    color: #8122bd;
}
