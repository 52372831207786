.input-file-container > input {
    display: none;
}

.input-file-container img {
    width: 30px;
    margin: 0px;
}

.input-file-container label {
    margin: 0px;
}
.input-file-container {
    margin-bottom: 5px;
}

.input-file-name {
    margin-left: 5px;
}