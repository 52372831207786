.input-error {
    color: red;
    text-align: center;
    margin: 0px;
    padding: 5px;
    font-weight: normal;
    font-size: 13px;
    list-style: none;
}

.loading {
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    line-height: 22px;
    color: #fff;
}