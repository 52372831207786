.diet-main-container {
    background-repeat: no-repeat; 
    background-size: 100% 250px;
    background-image: linear-gradient(45deg, #8122bd 0%, #70b4ee 100%);   
}

.diets-app {
    min-height: 450px;
}

.diet-dashboard-container {
    margin-bottom: 50px;
}

.diet-container {
    width: 296px;
    height: 410px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    margin: auto;
    margin-bottom: 20px;
    overflow: auto;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #9aa0aa;
    padding: 30px;
}