.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(52,52,52,0.65);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1001;
}
