.appointments-warning {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-between;
    padding: 20px;
}

.appointments-warning-sized {
    width: 80%;
    min-height: 307px;
}

.appointments-warning-fixed-sized {
    width: 307px;
    min-height: 307px;
}

.appointments-warning-text {

    font-family: Lato;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #9aa0aa;
    padding-left: 27px;
    padding-right: 27px;
    margin: 15px 0px 15px 0px;
}

.appointments-warning-close {
    cursor: pointer;
    align-self: flex-end;
}
