.status-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.status-screen-error {
    background: #c91120;   
}

.status-screen-container {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    color: #fff;
}

.status-screen-title {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    margin-bottom: 25px;
}

.status-screen-content {
    margin-top: 30px;
}


