.reset-password-container {
    text-align: center;
    margin-top: 160px;
    color: #fff;
}

.reset-password-message {
    color: #9aa0aa;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    margin-bottom: 20px;
    max-width: 310px;
}