
.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-white-container {
    margin-top: 50px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.logo-white {
    width: 150px;
}

.register-container {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: left;
    color: #3a3a3a;
    font-weight: 500;
    text-align: center;
}

.register-link {
    cursor: pointer;
}

.form-background {
    background-repeat: no-repeat; 
    background-size: 100% 300px;
    width: 100%;
    background-image: linear-gradient(to left, #ebb178 0%, #f9595c 44.04%, #f94f5d 100%); 
}

.form-footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-container-login {
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: #f94f5d;
}

.messages-container {
    margin: 20px;
}

.inputs-group-container {
    width: 100%;
}

.inputs-group-center {
    margin: auto;
}

.forgot-password-link {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    text-align: left;
    color: #9aa0aa;
    cursor: pointer;
    margin-top: 30px;
}