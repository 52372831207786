.my-payments-main-container {
    background-repeat: no-repeat; 
    background-size: 100% 70px;
    background-image: linear-gradient(to right, #2196aa 0%, #33ccae 100%);    
}

.my-payments-app {
    min-height: 450px;
}

.my-payments-container {
    margin-top: 40px;
    position: relative;
    margin-bottom: 70px;
}

.main-historical-payment-container {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 335px;
    margin: auto;
    margin-bottom: 10px;
    height: 64px;
}

.historical-payment-amount {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #343434;    
}

.historical-payment-description {
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    text-align: left;
    color: #343434;
    opacity: 0.6;    
}

.last-payments-title {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: center;
    color: #343434;
    margin-bottom: 25px;
}

.pending-payment-container {
    position: relative;
    margin: auto;
    max-width: 310px;
    margin-bottom: 40px;
}

.end-treatment-footer {
    position: fixed;
    bottom: 0;
    padding: 10px;
    text-align: center;
    width: 100%;
    cursor: pointer;
    color: #fff;
    background-image: linear-gradient(to right, #2196aa 0%, #33ccae 100%);
}

.end-treatment-confirmation {
    font-size: 12px;
    color: #727272;
    padding: 15px;
    text-align: center;
    background-size: 100%;
}

