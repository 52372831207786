.notifications-main-container {
    background-repeat: no-repeat; 
    background-size: 100% 390px;
    background-image: linear-gradient(to left, #ebb178 0%, #f9595c 44.04%, #f94f5d 100%);    
}

.notifications-app {
    min-height: 450px;
}

.notifications-container {
    margin-top: 40px;
}

.main-notification-container {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 335px;
    margin: auto;
    margin-bottom: 10px;
    height: 64px;
}

.notification-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.notification-checkmark {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;
}

.notification-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #343434;    
}

.notification-subtitle {
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    text-align: left;
    color: #343434;
    opacity: 0.6;
}

.notification-unread {
    cursor: pointer;
}