.markup-container {
    padding: 15px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    overflow: auto;
    max-height: 400px;
    font-family: Lato;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #727272;
}

.walkthrough-main {
    position: relative;
    width: 300px;
    margin: auto;
}

.walkthrough-container .gradient-div {
    height: 200px;
}

.navigable-content {
    position: relative;
}

.navigable-content a {
    position: absolute;
    right: 0px;
    bottom: -27px;
}

