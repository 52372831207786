.gradient-button {
    height: 45px;
    width: 250px;
    border-radius: 22.37px;
    line-height: 19px;
    text-align: center;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    cursor: pointer;
    border: 0px;
}