.appointments {
    position: fixed;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #207cdf 0%, #2884e4 55.77%, #8ed8d0 100%);
}

.appointments-app {
    width: 360px;
    height: 100%;
    overflow: auto;
    margin: auto;
    position: relative;
}

.appointments-content {
    position: absolute;
    top: 70px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.appointment-day {
    font-weight: normal;
    font-size: 9px;
    letter-spacing: 0.08em;
    line-height: 15px;
    text-align: center;
    color: #fff;
    cursor: default;
}

.appointment-day-container {
    width: 39px;
    height: 39px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 4px;
}

.appointment-day-container-selected {
    background: rgba(58, 149, 240, 0.62);
}

.appointment-day-disabled {
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 17px;
    opacity: 0.3;
}

.appointment-day-week-day {
    color: rgba(255, 255, 255, 0.5);
}

.appointment-day-available {
    cursor: pointer;
}

.appointment-day-selected {
    cursor: pointer;
}

.appointment-notification {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: white;
    margin: auto;
    position: absolute;
    bottom: 5px;
    left: 50%;
    margin-left: -2.5px;
}
.appointments-calendar-container {
    width: 100%;
}

.appointments-calendar {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 330px;
}

.appointments-title {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: center;
    color: #fff;
    padding-top: 20px;
}


.appointments-month {
    width: 360px;
    margin: auto;
}

.appointments-month-selector {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.02em;
    line-height: 22px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin: auto;
}

.appointments-month-selector img {
    cursor: pointer;
}

.appointment-availability-item-selection {
    cursor: pointer;
}

.appointment-type-selection {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-top: 10px;
}

.appointment-type-selection img {
    padding-left: 15px;
}


.appointments-month-line {
    background-color: white;
    height: 0px;
    background: transparent;
    border: 0.5px solid #fff;
    margin: 15px 0px 15px 0px;
}

.appointments-background-wrapper {
    width: 100%;
    background-repeat: no-repeat; 
    background-size: 100% 100%;
    background-image: linear-gradient(rgb(255,255,255), rgb(255,255,255));
    background-position: 0px 40px;
    padding-bottom: 10px;
}