.birthdate-form {
    padding: 30px;
}

.signup-gradient-background {
    height: 335px;
}

.form-button-container {
    text-align: center;
    padding-top: 20px;
}

.form-navigation-container {
    margin: auto;
    width: 300px;
}

.status-screen-emphasis {
    margin-top: 35px;
}

.signup-profile-picture-container {
    text-align: center;
    margin-bottom: 20px;
}