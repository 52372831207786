

.appointment-availability {

}

.appointment-availability-list {
    
}

.appointment-availability-item {
    padding: 15px 10px 10px 15px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 320px;
    margin: auto;
    margin-bottom: 15px;
}

.appointment-availability-item-hour-container {
    display: flex;
    flex-direction: row;
}

.appointment-availability-item-hour {
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.08em;
    line-height: 21px;
    text-align: left;
    color: #343434;
}

.appointment-availability-item-period {
    font-weight: normal;
    font-size: 7px;
    letter-spacing: 0.08em;
    line-height: 20px;
    text-align: left;
    color: #343434;
    opacity: 0.5;
    padding-left: 6px;
}

.appointment-availability-item-location-mode-container {
    width: 160px;
}
.appointment-availability-item-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #343434;
}

.appointment-availability-item-address {
    font-weight: normal;
    font-size: 11px;
    line-height: 19px;
    text-align: left;
    color: #343434;
    opacity: 0.6;
}

.appointment-availability-item-option {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: left;
    color: #343434;    
}

.appointment-user-item {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #9aa0aa;
}

.appointment-user-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
}


