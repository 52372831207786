.background-container {
    background-image: url('fondo_2023.jpg');
    height: 910px;
    background-repeat: no-repeat;
    background-position: center 0px;
}

.background-white {
  height: 840px;
  background: #fff;
  opacity: 0.55;
}


.main {
  max-width: 600px;
  margin: auto;
}

@media screen and (min-width: 1024px) {
  .main {
    max-width: 1004px;
  }
}

div.landing-logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    margin-top: 140px;
}
div.landing-logo-wrapper img {
    height: 310px;
    display: block;
}

.landing-container {
  position: relative;
}

.landing-content {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.landing-first-container {
  background-repeat: no-repeat; 
  background-size: 100% 750px;
  background-image: linear-gradient(to right,rgba(154,160,170, 0.1),rgba(154,160,170, 0.1));
  background-position: 0px 840px;
}

.landing-offset-section {
  position: relative;
  top: -70px;
}

.faq-section {
  padding-bottom: 40px;
}

.faqs {
  padding-bottom: 20px;
}

.footer-terms {
  cursor: pointer;
}

.landing-call-to-start {
  text-align: center;
  margin-top: 40px;
  margin-right: 30px;
  color: #f94f5d;
  background-color: #fff;
  cursor: pointer;
  padding: 12px;
  width: 226.41px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.landing-call-to-start:hover {
  color: #f94f5d;
  text-decoration: none;
}