.contact-us-section {
    background-image: linear-gradient(to left, #ebb178 0%, #f9595c 44.04%, #f94f5d 100%);
    text-align: center;
    color: #fff;
}

.contact-us-title {
    font-weight: 300;
    font-size: 2em;
    line-height: 22px;
    padding-top: 71px;
    padding-bottom: 7px;
}

.contact-us-subtitle {
    font-weight: normal;
    font-size: 1em;
    line-height: 21px;
}

.contact-us-button {
    cursor: pointer;
    background: transparent;
    width: 226.41px;
    height: 44.75px;
    border-radius: 22.37px;
    background: #fff;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #f94f5d;
    margin: auto;
    border: 0px;
}

.contact-us-form {
    max-width: 800px;
    padding: 0px 20px 0px 20px;
    margin: 85px auto 39px auto;
}

.contact-us-form img {
    width: 20px;
    margin-right: 20px;
}

.contact-us-form input {
    width: 100%;
    background: none;
    border: 0px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 70px;
}

.contact-us-form textarea {
    margin-top: 20px;
    width: 100%;
    background: none;
    border: 0px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 50px;
}

.contact-us-form textarea::placeholder {
    font-weight: normal;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 23px;
    text-align: left;
    color: #fff;
    opacity: 0.5;
}

.contact-us-form input::placeholder {
    font-weight: normal;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 23px;
    text-align: left;
    color: #fff;
    opacity: 0.5;
}

.contact-us-icons {
    margin-top: 33px;
    padding-bottom: 19px;
    display: flex;
    justify-content: center;
}

.contact-us-icons img {
    margin-left: 10px;
}

.contact-us-success {
    font-weight: normal;
    font-size: 13px;
    color: #9aa0aa;
    margin-top: 10px;
}

.contact-us-form-row {
    display: flex;
}