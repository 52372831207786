.company-history-layer {
    background: #fff;
}

.our-history {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    padding: 45px 34px 45px 34px;
    margin-bottom: 70px;
}
  
.our-history p {
    font-family: Lato;
    font-weight: normal;
    font-size: 1em;
    line-height: 1.8em;
    text-align: left;
    color: #727272;
}
.about-us-section {
    margin-bottom: 100px;
}
.about-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
  
.about-item {
    width: 307px;
    padding: 25px;
    text-align: center;
    border-radius: 4px;
    background: #f94f5d;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    margin: 0px 0px 40px 0px;
}

@media screen and (max-width: 1024px) {
    .about-item {
        margin: 0px auto 40px auto;
    }
}
  
.about-item-title {
    font-weight: 300;
    font-size: 2em;
    line-height: 2em;
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
    padding: 5px;
}
  
.about-item-text {
    font-weight: normal;
    font-size: 1em;
    line-height: 22px;
    text-align: left;
    color: #fff; 
    padding: 15px; 
}
