.dashboard-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding-top: 22px;
}

.dashboard-navigation-title {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 24px;
    text-align: left;
    color: #fff;
}

.dashboard-profile-pic {
    width: 46px;
    height: 46px;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 22px;
    object-fit: cover;
    object-position: center;
}

.empty-dashboard-profile-pic img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 22px;
}
