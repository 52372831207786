.whatsapp-float{
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:100;
}

.whatsapp-float-custom {
    margin-top: 16px;
}