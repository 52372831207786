.edit-profile-header-name {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 33px;
}


.edit-profile-main-container {
    background-repeat: no-repeat; 
    background-size: 100% 390px;
    background-image: linear-gradient(45deg, #af1697 0%, #f9764f 100%);
}
