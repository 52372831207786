.payment-container {
    padding: 15px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    overflow: auto;
    max-height: 400px;
    font-family: Lato;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #9aa0aa;
}

.payment-container-button {
    background: none;
    border: 0px;
    padding: 0px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    bottom: -40px;
}

.payment-container-main {
    margin-bottom: 35px;
}

.payment-subtitle {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 21px;
    color: #1d1d26;
    opacity: 0.5;
    margin: 10px;
}

.payment-title {
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 15px;
    color: #1d1d26;
    opacity: 0.5;       
}
.payment-amount {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    color: #343434;
    justify-content: center;
    align-items: center;
    height: 40px;
}
.main-amount {
    font-weight: 300;
    font-size: 45px;
    letter-spacing: 0.02em;
    line-height: 24px;
    padding: 0px;
}

.extras-amount {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 20px;
}

.decimal-part {
    align-self: flex-start;
}

.legal-warning-container img {
    width: 50px;
}

.legal-warning-container-scroll {
    margin: 20px;
    overflow: scroll;
    height: 350px;
    padding: 10px;
}

.legal-warning-container-scroll ol{ margin:0;padding:0} 
.legal-warning-container-scroll table td, table th {padding:0}
.legal-warning-container-scroll .c7{padding-top:0pt;padding-bottom:10pt;line-height:1.1500000000000001;orphans:2;widows:2;text-align:left;height:11pt}
.legal-warning-container-scroll .c6{padding-top:0pt;padding-bottom:0pt;line-height:1.0;orphans:2;widows:2;text-align:justify}
.legal-warning-container-scroll .c2{text-decoration-skip-ink:none;font-size:10pt;-webkit-text-decoration-skip:none;color:#0000ff;text-decoration:underline}
.legal-warning-container-scroll .c5{font-weight:400;text-decoration:none;vertical-align:baseline;font-style:normal}
.legal-warning-container-scroll .c4{background-color:#ffffff;max-width:425.2pt;padding:70.8pt 85pt 70.8pt 85pt}
.legal-warning-container-scroll .c1{color:inherit;text-decoration:inherit}
.legal-warning-container-scroll .c0{color:#9aa0aa;font-size:10pt}
.legal-warning-container-scroll .c3{font-weight:700}
.legal-warning-container-scroll .title{padding-top:24pt;color:#9aa0aa;font-weight:700;font-size:36pt;padding-bottom:6pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll .subtitle{padding-top:18pt;color:#666666;font-size:24pt;padding-bottom:4pt;font-family:"Georgia";line-height:1.1500000000000001;page-break-after:avoid;font-style:italic;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll li{color:#9aa0aa;font-size:11pt}
.legal-warning-container-scroll p {margin:0;color:#9aa0aa;font-size:11pt}
.legal-warning-container-scroll h1{padding-top:24pt;color:#9aa0aa;font-weight:700;font-size:24pt;padding-bottom:6pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll h2{padding-top:18pt;color:#9aa0aa;font-weight:700;font-size:18pt;padding-bottom:4pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll h3{padding-top:14pt;color:#9aa0aa;font-weight:700;font-size:14pt;padding-bottom:4pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll h4{padding-top:12pt;color:#9aa0aa;font-weight:700;font-size:12pt;padding-bottom:2pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll h5{padding-top:11pt;color:#9aa0aa;font-weight:700;font-size:11pt;padding-bottom:2pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}
.legal-warning-container-scroll h6{padding-top:10pt;color:#9aa0aa;font-weight:700;font-size:10pt;padding-bottom:2pt;line-height:1.1500000000000001;page-break-after:avoid;orphans:2;widows:2;text-align:left}