body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a {
  color: inherit;
  text-decoration: none;
} 

a:hover {
  color: inherit;
  text-decoration: none;
}

.grayed-section {
  background: rgba(154, 160, 170, 0.1);
  padding-top: 50px;
}


.team-section {
  margin-top: 63px;
}

.team-section h1 {
  margin-bottom: 40px;
}

.team-group {
  max-width: 800px;
  margin: auto;
}

.team-group-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #727272;
  padding-top: 40px;
  padding-bottom: 30px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-member {
  width: 225px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 36px;
  margin-left: 30px;
}

@media screen and (max-width: 1024px) {
  .team-member {
      margin: 0px auto 20px auto;
  }
}

.team-member .member-name {
  font-family: Lato;
  font-weight: 300;
  font-size: 1em;
  line-height: 14px;
  text-align: center;
  color: #727272;
  margin: 13px 0px 8px 0px;
}

.team-member .member-description {
  font-family: Lato;
  font-weight: bold;
  font-size: 0.8em;
  line-height: 15px;
  text-align: center;
  color: #f94f5d;
  margin-bottom: 15px;
}

.team-member img {
  max-width: 225px;
  text-align: center;
}

.divisor {
  width: 20px;
}

.gradient-div {
  background: linear-gradient(to left, #ebb178 0%, #f9595c 44.04%, #f94f5d 100%);
  color: white;
}

.faq-container {
  padding: 50px;
}

.faq-item-question {
  font-weight: 300;
  font-size: 1.1em;
  text-align: left;
  color: #727272;
  padding-left: 30px;
}

.faq-button-symbol {
  font-weight: normal;
  font-size: 1.1em;
  line-height: 30px;
  text-align: left;
  color: #727272;
}

.faq-item-answer {
  font-weight: normal;
  font-size: 1em;
  line-height: 22px;
  text-align: left;
  color: #f94f5d;
  margin-left: 5px;
}

.faqs {
  margin-left: 10px;
  margin-right: 10px;
}

.light-section h1 {
  font-weight: 300;
  font-size: 2em;
  line-height: 22px;
  text-align: center;
  color: #f94f5d;
}

.light-section h2 {
  font-weight: normal;
  font-size: 0.9em;
  line-height: 21px;
  text-align: center;
  color: #9aa0aa;
  margin-bottom: 40px;
}

.question-separator {
  height: 0px;
  background: transparent;
  border: 1px solid #9aa0aa;
  opacity: 0.15;
}

.faq-button {
  background: none;
  border: none;
  padding-top: 12px;
}


.white-divisor {
  margin: auto;
  width: 17.26px;
  height: 2.88px;
  background: #fff;
}

.footer {
  background: #727272;
  text-align: center;
  padding: 10px;
}
.footer span {
  font-weight: normal;
  font-size: 0.8em;
  line-height: 22px;
  text-align: center;
  color: #fff;
}

.button-blue-gradient {
  background: linear-gradient(to right, #207cdf 0%, #65b6d6 76.35%, #8ed8d0 100%);
}

.button-red-gradient {
  background: linear-gradient(to right,#ff0015 0%, #ba0514 100%);  
}

.button-red-strong-gradient {
   background: linear-gradient(to right, #af1697 0%, #f9764f 100%);
}

.div-blue-gradient {
  background: linear-gradient(to right, #207cdf 0%, #207cdf 50.76%, #8ed8d0 100%);
}

.faq-row {
  display: flex;
}
