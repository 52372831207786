.profile-input-description {
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: left;
    color: #727272;
    font-weight: 400;
}

.icon-input-container {
    display: flex;
    align-items: center;
    width: 100%;
}
.input-container {
    width: 100%;
    margin-left: 5px;
    margin-bottom: 10px;
}

.input-container select {
    border: none;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 23px;
    color: #9aa0aa;
    text-align: left;
    margin-right: 21px;
    background: none;
    border-bottom: 1px solid rgba(154,160,170, 0.2);
    -webkit-appearance: none;
    border-radius: 0px;
}

.input-container select:focus {
    border-bottom: 1px solid rgb(249, 79, 93);
    box-shadow: none;
    outline: none;
}

.input-container input::placeholder { 
    color: #9aa0aa; 
    opacity: 0.5;
    border: none;
    font-weight: normal;
    font-size: 11px;
    letter-spacing: 0.02em;
    line-height: 23px;
}


.inputs-group {
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    width: 300px;
    background: #fff;
    padding: 20px;
}

.input-container input {
    font-size: 12px;
    height: 25px;
    border: 0px;
    color: #9aa0aa;
    background: none;
    border-bottom: 1px solid rgba(154,160,170, 0.2);
    border-radius: 0;
    outline: 0;
    width: 100%;
}

.input-container input:focus {
    border-bottom: 1px solid rgb(249, 79, 93);
    box-shadow: none;
}


.division-line {
    height: 1px;
    background: #9aa0aa;
    opacity: 0.2;
}

.icon-input-container img {
    width: 15px;
    margin: 5px;
}

.edit-profile-header-name {
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.02em;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 33px;
}

.edit-profile-form-container {
    margin: auto;
    width: 300px;
    padding-bottom: 40px;
}

.edit-profile-main-container {
    background-repeat: no-repeat; 
    background-size: 100% 390px;
    background-image: linear-gradient(45deg, #af1697 0%, #f9764f 100%);
}

.edit-profile-form-container .input-container {
    padding-bottom: 0px;
}

.medical-disclaimer {
    font-size: 12px;
    color: #9aa0aa;
    margin-top: 8px;
}